// import clone from 'clone';
import actions from './actions';
const initState = {
  passengertags: [],
  isDelete: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_PASSENGERTAG_DATAS_REDUCER: {
      return {
        ...state,
        passengertags: action.data,
      };
    }

    case actions.GET_BY_ID_REDUCER: {
      return {
        ...state,
        passengerById: action.passenger,
        isDelete: false,
      };
    }
    case actions.DELETE_FAILED:
      return {
        ...state,
        isDelete: false,
      };
    case actions.DELETE_SUCCESS:
      return {
        ...state,
        // assets:[],
        isDelete: true,
      };
    default:
      return state;
  }
}
