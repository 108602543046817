const actions = {
  GET_USERROLES: "GET_USERROLES",
  ADD_USERROLE: "ADD_USERROLE",
  ADD_USERROLE_SUCCESS: "ADD_USERROLE_SUCCESS",
  DELETE_USER_ROLE: "DELETE_USER_ROLE",
  GET_USERROLE_REDUCER: "GET_USERROLE_REDUCER",
  GET_USER_ROLE_BY_ID: "GET_USER_ROLE_BY_ID",
  GET_ROLE_BY_ID_REDUCER: "GET_ROLE_BY_ID_REDUCER",
  UPDATE_USER_ROLE: "UPDATE_USER_ROLE",
  DELETE_USER_ROLE_SUCCESS:'DELETE_USER_ROLE_SUCCESS',

  getAllRoles: () => ({ type: actions.GET_USERROLES }),
  addRole: (sendData) => {
    return (dispatch, getState) => {
      dispatch({ type: actions.ADD_USERROLE, payload: { sendData: sendData } });
    };
  },
  getUserRoleById: (id) => {
    return (dispatch) => {
      dispatch({ type: actions.GET_USER_ROLE_BY_ID, payload: { id: id } });
    };
  },
  deleteUserRoleData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_USER_ROLE,
        payload: {id:selected}
      });
    };
  },
  updateUserRoleData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_USER_ROLE,
        payload: { sendData: sendData, id: id },
      });
    };
  },
};
export default actions;
