// import clone from 'clone';
import actions from './actions';
const initState = {
  contacts: [],
  contact: {},
  isChanged: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_PORTFACILITY_CONTACT_REDUCER:
      return {
        ...state,
        contacts: action.data,
        isChanged: false,
      };
    case actions.CHANGED_CONTACT_REDUCER:
      return {
        ...state,
        isChanged: true,
      };
    default:
      return state;
  }
}
