// import clone from 'clone';
import actions from './actions';
const initState = {  
  scheduledaudits:[],
  scheduledaudit:{},
  isDelete:false,
  isSaved:false,
  isLoading: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.ADD_SCHEDULED_AUDIT_SUCCESS:
      return {
        ...state,             
        scheduledaudits: [],   
        isLoading: false, 
        isSaved:true    
      };
    case actions.ADD_FAILED:
      return {
        ...state,        
        scheduledaudits: [],    
        isLoading: false, 
        isSaved:false        
      };
    case actions.GET_SCHEDULED_AUDIT_REDUCER: {
      return {
        ...state,
        scheduledaudits: action.scheduledaudits,      
        isDelete:false,
        isLoading: true,
        isSaved:false
      };
    }
    case actions.GET_SCHEDULED_AUDIT_BY_ID_REDUCER: {
      return {
        ...state,
        isLoading: true,
        scheduledaudit: action.scheduledaudit,   
        isSaved:false     
      };
    }
    case actions.UPDATE_SUCCESS:
      return {
        ...state,      
        scheduledaudits: [],
        isLoading: false,
        isSaved:false
        //isUpdateUser:true
      };
    case actions.DELETE_FAILED:
      return {
        ...state,    
        isLoading: false,
        isDelete:false
      };
    case actions.DELETE_SUCCESS:
      return {
        ...state, 
        isLoading: false,  
        isDelete:true
      };  
    default:
      return state;
  }
}
