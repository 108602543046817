/*eslint no-unused-vars: ["off"]*/
/*eslint eqeqeq: ["off"]*/
/*eslint jsx-a11y/alt-text: ["off"]*/
/*eslint jsx-a11y/anchor-is-valid: ["off"]*/
/*eslint  array-callback-return: ["off"]*/
/*eslint  react-hooks/exhaustive-deps: ["off"]*/
import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        <Routes />
      </>
    </AppProvider>
  </Provider>
);
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
