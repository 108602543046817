const actions = {
  GET_WARRANTIES: "GET_WARRANTIES",
  ADD_WARRANTY: "ADD_WARRANTY",
  GET_WARRANTIES_REDUCER:"GET_WARRANTIES_REDUCER",
  UPDATE_WARRANTY: "UPDATE_WARRANTY",
  DELETE_WARRANTY:"DELETE_WARRANTY",
  DELETE_WARRANTY_SUCCESS:"DELETE_WARRANTY_SUCCESS",
  
  addWarranty: (sendData) => {
    return (dispatch, getState) => {
      dispatch({ type: actions.ADD_WARRANTY, payload: { sendData: sendData } });
    };
  },

  getWarrantyData: (assetId) => {
    return (dispatch) => {      
      dispatch({ type: actions.GET_WARRANTIES, payload: { id: assetId } });
    };
  },

  deleteWarrantyData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_WARRANTY,
        payload: {id:selected}
      });
    };
  },

  updateWarrantyData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_WARRANTY,
        payload: { sendData: sendData, id: id },
      });
    };
  },
};
export default actions;
