/*eslint no-unused-vars: ["off"]*/
/*eslint eqeqeq: ["off"]*/
/*eslint jsx-a11y/alt-text: ["off"]*/
/*eslint jsx-a11y/anchor-is-valid: ["off"]*/
/*eslint  array-callback-return: ["off"]*/
/*eslint  react-hooks/exhaustive-deps: ["off"]*/
import React from 'react';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <p>Loading failed! Please reload.</p>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
