const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SIGNUP_REQUEST:'SIGNUP_REQUEST',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }), 
  login: (email,password) => {      
    return dispatch => {    
      dispatch({ type: actions.LOGIN_REQUEST, payload: {token:false,email:email,password:password} })   
    }
  },
  forgotPassword: (email) => {      
    return dispatch => {    
      dispatch({ type: actions.FORGOT_PASSWORD, payload: { email:email } })   
    }
  },
  resetPassword: (sendData) => {      
    return dispatch => {    
      dispatch({ type: actions.RESET_PASSWORD, payload: { sendData: sendData } })   
    }
  },
  signup: ({sendData, history}) => {
    return dispatch => {    
      dispatch({ type: actions.SIGNUP_REQUEST, payload: {sendData, history} }) 
    }
  },
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
