/*eslint no-unused-vars: ["off"]*/
/*eslint eqeqeq: ["off"]*/

/*eslint  array-callback-return: ["off"]*/
import { all, takeEvery, put,call } from 'redux-saga/effects';
import axios from 'axios'
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';
import actions from './actions';


const onCallReqeust = async (URI) =>
  await axios
  .get(URI)  
    .then(res => res)
    .catch(error => error);
const onPostCallReqeust = async (sendData,URI) =>
  await axios
  .post(URI,sendData)  
    .then(res => res)
    .catch(error => error);
const onPutCallReqeust = async (sendData,URI) =>
  await axios
  .put(URI,sendData)  
    .then(res => res)
    .catch(error => error);    
const onDeleteCallReqeust = async (URI) =>
  await axios
  .delete(URI)  
    .then(res => res)
    .catch(error => error);  
export function* getRole() {
  axios.defaults.headers.get['Authorization'] = localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/userrole`             
    );
    if(callResult.response != undefined){
      notification('error',callResult.response.data.msg);
    }
    else{
      var temp=[];    
      callResult.data.data.map((value, index) => {          
           value.key=value._id.toString();
           temp.push(value);
       });     
      
       yield put({
         type: actions.GET_USERROLE_REDUCER,
         userroles:temp,//createDemoData(),
       });
    }  
   
  }
  catch (error) {
    notification('error',"Internal server error!")
  }  
}
export function* addRole({payload}) { 
  axios.defaults.headers.post['Authorization'] = localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onPostCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/userrole`          
    );

    if(callResult.response != undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{
        notification('success',callResult.data.msg)
        yield put({
          type: actions.GET_USERROLES,
          // userroles:[],//createDemoData(),
        });
    }
  }
  catch (error) {  
    notification('error', "Server Internal error!")
  }  
}
export function* getRoleById({payload}) {
  axios.defaults.headers.get['Authorization'] = localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/userrole/${payload.id}`             
    );
   
 
    if(callResult.response != undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{       
        yield put({
        type: actions.GET_ROLE_BY_ID_REDUCER,
        data:callResult.data.data,
        msg:null
      });
    }    
  }
  catch (error) {    
    notification('error',"Internal server error!")
  }  
}
export function* updateUserRoleData({payload}) { 
  axios.defaults.headers.put['Authorization'] =  localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onPutCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/userrole/${payload.id}`          
    );
  
    if(callResult.response != undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{
     notification('success',callResult.data.msg)
     yield put({
       type: actions.GET_USERROLES,
       // userroles:[],//createDemoData(),
     });
    }  
  }
  catch (error) {  
    notification('success',"Server Internal error!")   
  }  
}
export function* deleteRoleData({payload}) { 
  axios.defaults.headers.delete['Authorization'] =localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onDeleteCallReqeust,     
      `${siteConfig.apiUrl}/userrole/${payload.id}`          
    );
    if(callResult.response != undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{
      notification('success',callResult.data.msg)
      yield put({
        type: actions.DELETE_USER_ROLE_SUCCESS,
        msg:callResult.data.msg,
      });
    }     

  }
  catch (error) {     
    notification('error',"Server Internal error!")      
  }  
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_USERROLES, getRole),
    yield takeEvery(actions.ADD_USERROLE, addRole),
    yield takeEvery(actions.GET_USER_ROLE_BY_ID, getRoleById),
    yield takeEvery(actions.UPDATE_USER_ROLE, updateUserRoleData),
    yield takeEvery(actions.DELETE_USER_ROLE, deleteRoleData),     
  ]);
}
