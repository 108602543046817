import { all, takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';
import actions from './actions';

const onCallReqeust = async (URI) =>
  await axios
    .get(URI)
    .then((res) => res)
    .catch((error) => error);

export function* getSite() {
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/tripleseatsite`
    );
    yield put({
      type: actions.GET_TRIPLESEAT_SITE_REDUCER,
      site: callResult.data[0], //createDemoData(),
    });
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(actions.GET_TRIPLESEAT_SITE, getSite)]);
}
