/*eslint no-unused-vars: ["off"]*/
/*eslint eqeqeq: ["off"]*/

/*eslint  array-callback-return: ["off"]*/
import { all, takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
// import { getToken } from '@iso/lib/helpers/utility';
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';
import actions from './actions';

const onCallReqeust = async (URI) =>
  await axios
    .get(URI)
    .then((res) => res)
    .catch((error) => error);
const onPostCallReqeust = async (sendData, URI) =>
  await axios
    .post(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onPutCallReqeust = async (sendData, URI) =>
  await axios
    .put(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onDeleteCallReqeust = async (URI) =>
  await axios
    .delete(URI)
    .then((res) => res)
    .catch((error) => error);
export function* getPurchaseOrder({ payload }) {
  axios.defaults.headers.get['Authorization'] = localStorage.getItem(
    'id_token'
  );
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/purchaseorder`
    );

    if (callResult.response != undefined) {
      notification('error', callResult.response.data.msg);
    } else {
      var temp = [], temp1 = [];
      callResult.data.data.map((value, index) => {
        value.key = parseInt(index) + 1;
        temp1.push(value);
      });

      if(payload.vesselId != 'all') {
        temp1.map(item=>{
          if(payload.vesselId == item.intVesselID) temp.push(item);
        });
      } else {
        temp = temp1;
      }
      yield put({
        type: actions.GET_PURCHASE_ORDER_REDUCER,
        data: temp, //createDemoData(),
      });
    }
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}
export function* getPurchaseOrderById({ payload }) {
  axios.defaults.headers.get['Authorization'] = localStorage.getItem(
    'id_token'
  );
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/purchaseorder/${payload.id}`
    );

    if (callResult.response != undefined) {
      notification('error', callResult.response.data.msg);
    } else {
      yield put({
        type: actions.GET_PURCHASE_ORDER_BYID_REDUCER,
        data: callResult.data.data,
        msg: null,
      });
    }
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}


export function* getPrintData({ payload }) {
  axios.defaults.headers.get['Authorization'] = localStorage.getItem(
    'id_token'
  );
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/purchaseorder/print/${payload.id}`
    );
    if (callResult.response != undefined) {
      notification('error', callResult.response.data.msg);
    } else {
      yield put({
        type: actions.GET_PURCHASE_ORDER_PRINT_DATA_REDUCER,
        data: callResult.data.data,
        msg: null,
      });
    }
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}

export function* addPurchaseOrder({ payload }) {
  axios.defaults.headers.post['Authorization'] = localStorage.getItem(
    'id_token'
  );
  try {
    const callResult = yield call(
      onPostCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/purchaseorder`
    );

    if (callResult.response != undefined) {
      notification('error', callResult.response.data.msg);
    } else {
      notification('success', callResult.data.msg);
      yield put({
        type: actions.DELETE_PURCHASE_ORDER_REDUCER,
        msg: callResult.data.msg,
      });
    }
  } catch (error) {
    yield put({ type: actions.ADD_FAILED, msg: 'Server Internal error!' });
  }
}


export function* sendMailSupplier({ payload }) {
  axios.defaults.headers.post['Authorization'] = localStorage.getItem(
    'id_token'
  );
  try {
    const callResult = yield call(
      onPostCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/purchaseorder/sendmail`
    );

    if (callResult.response != undefined) {
      notification('error', callResult.response.data.msg);
    } else {
      notification('success', callResult.data.msg);
      // yield put({
      //   type: actions.GET_PURCHASE_ORDER,
      //   msg: callResult.data.msg,
      // });
    }
  } catch (error) {
    yield put({ type: actions.ADD_FAILED, msg: 'Server Internal error!' });
  }
}
export function* updatePurchaseOrder({ payload }) {
  axios.defaults.headers.put['Authorization'] = localStorage.getItem(
    'id_token'
  );
  try {
    const callResult = yield call(
      onPutCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/purchaseorder/${payload.id}`
    );

    if (callResult.response != undefined) {
      notification('error', callResult.response.data.msg);
    } else {
      notification('success', callResult.data.msg);
      yield put({
        type: actions.GET_PURCHASE_ORDER_BYID,
        payload: { id: payload.id }
      });
    }
  } catch (error) {
    notification('success', 'Server Internal error!');
  }
}

export function* deletePurchaseOrder({ payload }) {
  axios.defaults.headers.delete['Authorization'] = localStorage.getItem(
    'id_token'
  );
  try {
    const callResult = yield call(
      onDeleteCallReqeust,
      `${siteConfig.apiUrl}/purchaseorder/${payload.id}`
    );
    if (callResult.response != undefined) {
      notification('error', callResult.response.data.msg);
    } else {
      notification('success', callResult.data.msg);
      yield put({
        type: actions.DELETE_PURCHASE_ORDER_REDUCER,
      });
    }
  } catch (error) {
    notification('error', 'Server Internal error!');
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_PURCHASE_ORDER, getPurchaseOrder),
    yield takeEvery(actions.ADD_PURCHASE_ORDER, addPurchaseOrder),
    yield takeEvery(actions.GET_PURCHASE_ORDER_BYID, getPurchaseOrderById),
    yield takeEvery(actions.GET_PURCHASE_ORDER_PRINT_DATA, getPrintData),    
    yield takeEvery(actions.UPDATE_PURCHASE_ORDER, updatePurchaseOrder),
    yield takeEvery(actions.SEND_MAIL_SUPPLIER, sendMailSupplier),
    yield takeEvery(actions.DELETE_PURCHASE_ORDER, deletePurchaseOrder),
  ]);
}
