/*eslint no-unused-vars: ["off"]*/
/*eslint eqeqeq: ["off"]*/

/*eslint  array-callback-return: ["off"]*/
import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import axios from "axios";
import { getToken, clearToken } from "@iso/lib/helpers/utility";
import siteConfig from "@iso/config/site.config";
import actions from "./actions";
import notification from "@iso/components/Notification";

axios.defaults.headers.get["Content-Type"] = "application/json";
const onCallReqeust = async (sendData, URI) =>
  await axios
    .post(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onPutCallReqeust = async (sendData, URI) =>
  await axios
    .put(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
export function* loginRequest() {
  yield takeEvery("LOGIN_REQUEST", function* ({ payload }) {
    const { token, email, password } = payload;
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: token,
        profile: "Profile",
      });
    } else {
      const URI = `${siteConfig.apiUrl}/auth/login`;
      var sendData = {
        email: email,
        password: password,
      };
      try {
        const callResult = yield call(onCallReqeust, sendData, URI);

        if (callResult.response != undefined) {
          notification("error", callResult.response.data.msg);
          yield put({
            type: actions.LOGIN_ERROR,
            msg: callResult.response.data.msg,
          });
        } else {
          localStorage.setItem("user_name",callResult.data.data.user.strFullName);
          localStorage.setItem("user_id",callResult.data.data.user.id);
          localStorage.setItem("user_avatar",callResult.data.data.user.strLink);

          yield put({
            type: actions.LOGIN_SUCCESS,
            token: callResult.data.data.token,
            user: callResult.data.data.user,
            profile: "Profile",
          });
        }
      } catch (error) {
        console.log(error);
        notification("error", "Server Internal error!");
      }
    }
  });
}
export function* signupRequest({ payload }) {
  var sendData = payload.sendData;
  const URI = `${siteConfig.apiUrl}/auth/register`;
  try {
    const callResult = yield call(onCallReqeust, {generalInfo: sendData}, URI);

    if (callResult.response != undefined) {
      notification("error", callResult.response.data.msg);
    } else {
      notification("success", "Successfully registered!!. please Login!");
      payload.history.push('/');
    }
  } catch (error) {
    notification("error", "Server Internal error!");
  }
}

export function* forgotPassword({ payload }) {
  var email = payload.email;
  const URI = `${siteConfig.apiUrl}/auth/forgot`;
  try {
    const callResult = yield call(onCallReqeust, { strEmailAddress: email }, URI);

    if (callResult.response != undefined) {
      notification("error", callResult.response.data.msg);
    } else {
      notification(callResult.data.status, callResult.data.msg)
    }
  } catch (error) {
    notification("error", "Server Internal error!");
  }
}

export function* resetPassword({ payload }) {
  const URI = `${siteConfig.apiUrl}/auth/reset`;
  try {
    const callResult = yield call(onCallReqeust, payload, URI);

    if (callResult.response != undefined) {
      notification("error", callResult.response.data.msg);
    } else {
      notification(callResult.data.status, callResult.data.msg)
    }
  } catch (error) {
    notification("error", "Server Internal error!");
  }
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {  
    yield localStorage.setItem("id_token", payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  axios.defaults.headers.put['Authorization'] = localStorage.getItem(
    'id_token'
  );

    var userInf={};
  userInf.strFullName = localStorage.getItem("user_name");
  userInf.userId = localStorage.getItem("user_id");
  try {   
    localStorage.removeItem('user_id');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_avatar');
    yield clearToken();
    const callResult = yield call(
      onPutCallReqeust,
      userInf,
      `${siteConfig.apiUrl}/users/logout`
    );  
  } catch (error) {
    console.log(error,'ddd');
  }
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
    const token = getToken().get("idToken");    
    if (token) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        profile: "Profile",
      });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    yield takeEvery(actions.LOGOUT, logout),
    yield takeEvery(actions.SIGNUP_REQUEST, signupRequest),
    yield takeEvery(actions.FORGOT_PASSWORD, forgotPassword),
    yield takeEvery(actions.RESET_PASSWORD, resetPassword),
  ]);
}
