import actions from './actions';
const initState = {
  events: [],
  event: {},
  success: false,
  isDelete: false,
};

export default function TripleEvent(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_TRIPLESEAT_EVENT_REDUCER: {
      return {
        ...state,
        events: action,
        success: true,
      };
    }
    case actions.UPDATE_SUCCESS: {
      return {
        ...state,
        success: true,
      };
    }
    case actions.GET_BY_ID_EVENT_REDUCER: {
      console.log(action, 'action');
      return {
        ...state,
        event: action.event.event,
        isDelete: false,
      };
    }
    case actions.DELETE_SUCCESS: {
      return {
        ...state,
        isDelete: true,
      };
    }
    case actions.DELETE_FAILED: {
      return {
        ...state,
        isDelete: false,
      };
    }
    default:
      return state;
  }
}
