const actions = {
  GET_POSITION: 'GET_POSITION',
  ADD_POSITION: 'ADD_POSITION',
  // ADD_CODE_TYPE_SUCCESS: "ADD_CODE_TYPE",
  GET_POSITION_REDUCER: 'GET_POSITION_REDUCER',
  UPDATE_POSITION: 'UPDATE_POSITION',
  DELETE_POSITION: 'DELETE_POSITION',

  getPositionData: () => ({ type: actions.GET_POSITION }),
  addPosition: (sendData) => {
    return (dispatch, getState) => {
      dispatch({ type: actions.ADD_POSITION, payload: { sendData: sendData } });
    };
  },

  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_POSITION,
        payload: { id: selected },
      });
    };
  },
  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_POSITION,
        payload: { sendData: sendData, id: id },
      });
    };
  },
};
export default actions;
