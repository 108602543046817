/*eslint no-unused-vars: ["off"]*/
/*eslint eqeqeq: ["off"]*/

/*eslint  array-callback-return: ["off"]*/
import { all, takeEvery, put,call } from 'redux-saga/effects';
import axios from 'axios'
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';
import actions from './actions';

const onCallReqeust = async (URI) =>
  await axios
  .get(URI)  
    .then(res => res)
    .catch(error => error);
const onPostCallReqeust = async (sendData,URI) =>
  await axios
  .post(URI,sendData)  
    .then(res => res)
    .catch(error => error);
const onPutCallReqeust = async (sendData,URI) =>
  await axios
  .put(URI,sendData)  
    .then(res => res)
    .catch(error => error);    
const onDeleteCallReqeust = async (URI) =>
  await axios
  .delete(URI)  
    .then(res => res)
    .catch(error => error);  
    
export function* getReceipes({ payload }) {
  axios.defaults.headers.get['Authorization'] = localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/receipe/assetID/${payload.id}`
    );    
    if(callResult.response!=undefined){
      notification('error',callResult.response.data.msg);
    }
    else{
      var temp=[];    
      callResult.data.data.map((value, index) => {
           value.key=parseInt(index)+1;
           temp.push(value);
       });
      
       yield put({
         type: actions.GET_RECEIPES_REDUCER,
         data: temp,
       });
    }   
  }
  catch (error) {
    notification('error',"Server Internal error!");
  }  
}

export function* addReceipe({ payload }) {
  axios.defaults.headers.post['Authorization'] = localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onPostCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/receipe`          
    );

    if(callResult.response!=undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{
        notification('success',callResult.data.msg)
        yield put({
          type: actions.GET_RECEIPES,
          payload: { id: payload.sendData.intAssetId }
        });
    }
  }
  catch (error) {  
    notification('error',"Server Internal error!") 
  }  
}

export function* updateReceipe({payload}) {
  axios.defaults.headers.put['Authorization'] =  localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onPutCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/receipe/${payload.id}`
    );
  
    if(callResult.response!=undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{
     notification('success',callResult.data.msg)
      yield put({
        type: actions.GET_RECEIPES,
        payload: { id: payload.sendData.intAssetId }
      });
    }  
  }
  catch (error) {  
    notification('success',"Server Internal error!")   
  }  
}

export function* deleteReceipe({ payload }) {
  axios.defaults.headers.delete['Authorization'] =localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onDeleteCallReqeust,     
      `${siteConfig.apiUrl}/receipe/${payload.id}`
    );
    if(callResult.response!=undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{
      notification('success',callResult.data.msg)
      yield put({
        type: actions.DELETE_RECEIPE_SUCCESS,
      });
    }     

  }
  catch (error) {     
    notification('error',"Server Internal error!")      
  }  
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_RECEIPES, getReceipes),
    yield takeEvery(actions.ADD_RECEIPE, addReceipe),
    yield takeEvery(actions.UPDATE_RECEIPE, updateReceipe),
    yield takeEvery(actions.DELETE_RECEIPE, deleteReceipe),    
  ]);
}
