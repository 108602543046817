// import clone from 'clone';
import actions from './actions';
const initState = {  
  portFacilities:[],
  portFacility:{},
  isChanged:false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_PORT_FACILITIES_REDUCER:
      return {
        ...state,             
        portFacilities: action.data,
        isChanged:false
      };    
    case actions.GET_PORT_FACILITY_BY_ID_REDUCER:
      return {
        ...state,
        portFacility: action.data,
        isChanged: false
      };
    case actions.CHANGED_REDUCER:
      return {
        ...state,      
        isChanged: true
      };
    default:
      return state;
  }
}
