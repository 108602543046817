import actions from './actions';
const initstate = {
  bookings: [],
  booking: {},
  isDelete: false,
};

export default function cardReducer(state = initstate, { type, ...action }) {
  switch (type) {
    case actions.GET_BOOKING_REDUCER: {
      return {
        ...state,
        bookings: action,
        isDelete: false,
      };
    }
    case actions.UPDATE_SUCCESS: {
      return {
        ...state,
        isDelete: false,
      };
    }
    case actions.DELETE_SUCCESS: {
      return {
        ...state,
        isDelete: true,
      };
    }
    default:
      return state;
  }
}
