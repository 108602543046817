import { all, takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';
import actions from './actions';

const onCallReqeust = async (URI) =>
  await axios
    .get(URI)
    .then((res) => res)
    .catch((error) => error);

export function* getUser() {
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/tripleseatuser`
    );
    yield put({
      type: actions.GET_TRIPLESEAT_USER_REDUCER,
      tripleusers: callResult.data.results, //createDemoData(),
    });
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}

export function* getByIdUser({ payload }) {
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/tripleseatuser/${payload.id}`
    );
    yield put({
      type: actions.GET_BY_ID_USER_REDUCER,
      tripleusers: callResult.data.data,
    });
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_TRIPLESEAT_USER, getUser),
    yield takeEvery(actions.GET_BY_ID_USER, getByIdUser),
  ]);
}
