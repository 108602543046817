const actions = {
  GET_TRIPLESEAT_ACCOUNT: 'GET_TRIPLESEAT_ACCOUNT',
  GET_TRIPLESEAT_ACCOUNT_REDUCER: 'GET_TRIPLESEAT_ACCOUNT_REDUCER',
  GET_BY_ID_ACCOUNT: 'GET_BY_ID_ACCOUNT',
  GET_BY_ID_ACCOUNT_REDUCER: 'GET_BY_ID_ACCOUNT_REDUCER',
  ADD_TRIPLESEAT_ACCOUNT: 'ADD_TRIPLESEAT_ACCOUNT',
  ADD_ACCOUNT_SUCCESS: 'ADD_ACCOUNT_SUCCESS',
  ADD_ACCOUNT_FAILE: 'ADD_ACCOUNT_FAILE',
  UPDATE_ACCOUNT_TRIPLE: 'UPDATE_ACCOUNT_TRIPLE',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILED: 'DELETE_FAILED',

  getAccount: () => ({ type: actions.GET_TRIPLESEAT_ACCOUNT }),

  addAccount: (sendData) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.ADD_TRIPLESEAT_ACCOUNT,
        payload: { sendData: sendData },
      });
    };
  },

  getById: (id) => {
    return (dispatch) => {
      dispatch({ type: actions.GET_BY_ID_ACCOUNT, payload: { id: id } });
    };
  },

  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ACCOUNT_TRIPLE,
        payload: { sendData: sendData, id: id },
      });
    };
  },
  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_ACCOUNT,
        payload: { id: selected },
      });
    };
  },
};
export default actions;
