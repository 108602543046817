export default {
  siteName: "SeaFairMiami",
  siteIcon: "ion-flash",
  footerText: `SeaFairMiami @ ${new Date().getFullYear()} Created by RedQ, Inc`,
  enableAnimatedRoute: false,
  apiUrl: "https://dev.node.seafairmiami.com/api",
  weatherApiUrl:
    "https://api.stormglass.io/v2/weather/point?lat=25.772320&lng=-80.185490&params=airTemperature,pressure,cloudCover,currentDirection,currentSpeed,gust,humidity,precipitation,seaLevel,swellDirection,swellHeight,swellPeriod,visibility,waterTemperature,waveDirection,waveHeight,wavePeriod,windDirection,windSpeed",
  weatherApiKey:
    "cc07cc62-6fc3-11eb-958b-0242ac130002-cc07cce4-6fc3-11eb-958b-0242ac130002",
  google: {
    analyticsKey: "UA-xxxxxxxxx-1",
  },
  dashboard: "/dashboard",
};
