/*eslint no-unused-vars: ["off"]*/
/*eslint eqeqeq: ["off"]*/
/*eslint jsx-a11y/alt-text: ["off"]*/
/*eslint jsx-a11y/anchor-is-valid: ["off"]*/
/*eslint  array-callback-return: ["off"]*/
/*eslint  react-hooks/exhaustive-deps: ["off"]*/
import actions from './actions';
import { all, takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';

const onCallReqeust = async (URI) =>
  await axios
    .get(URI)
    .then((res) => res)
    .catch((error) => error);
const onPostCallReqeust = async (sendData, URI) =>
  await axios
    .post(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onPutCallReqeust = async (sendData, URI) =>
  await axios
    .put(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onDeleteCallReqeust = async (URI) =>
  await axios
    .delete(URI)
    .then((res) => res)
    .catch((error) => error);

export function* getBooking() {
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/tripleseatbooking`
    );
    yield put({
      type: actions.GET_BOOKING_REDUCER,
      bookings: callResult.data.results, //createDemoData(),
    });
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}

export function* addBooking({ payload }) {
  try {
    const callResult = yield call(
      onPostCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseatbooking`
    );
    notification('success', 'Create Successfully!');
    yield put({
      type: actions.GET_BOOKING,
      payload: { id: payload.sendData.account_id },
    });
  } catch (error) {
    console.log(error);
    notification('error', 'Internal server error!');
  }
}

export function* updateData({ payload }) {
  try {
    const callResult = yield call(
      onPutCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseatbooking/${payload.id}`
    );
    notification('success', 'Update Successfully');
    yield put({
      type: actions.UPDATE_SUCCESS,
      payload: { id: payload.sendData.account_id },
    });
  } catch (error) {
    notification('error', 'Server Internal error!');
  }
}

export function* deleteData({ payload }) {
  try {
    const callRess = yield call(
      onDeleteCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseatbooking/${payload.id}`
    );
    notification('success', 'Delete Successfully');
    yield put({
      type: actions.DELETE_SUCCESS,
    });
  } catch (error) {
    notification('error', 'Server Internal error!');
  }
}

export default function* rootSaga() {
  yield all([yield takeEvery(actions.GET_BOOKING, getBooking)]);
  yield all([yield takeEvery(actions.ADD_BOOKING, addBooking)]);
  yield all([yield takeEvery(actions.UPDATE_BOOKING, updateData)]);
  yield all([yield takeEvery(actions.DELETE_BOOKING, deleteData)]);
}
