const actions = {
  ADD_PASSENGER: 'ADD_PASSENGER',
  GET_PASSENGER_DATAS: 'GET_PASSENGER_DATAS',
  GET_ALLPASSENGER_DATAS: 'GET_ALLPASSENGER_DATAS',
  GET_ALLPASSENGER_DATAS_REDUCER: 'GET_ALLPASSENGER_DATAS_REDUCER',
  GET_PASSENGER_DATAS_REDUCER: 'GET_PASSENGER_DATAS_REDUCER',
  UPDATE_PASSENGER: 'UPDATE_CHARTER',
  UPDATE_PASSENGER_REDUCER: 'UPDATE_PASSENGER_REDUCER',
  DELETE_PASSENGER: 'DELETE_PASSENGER',
  DELETE_PASSENGER_REDUCER: 'DELETE_PASSENGER_REDUCER',

  getAllPassengersData: () => ({ type: actions.GET_ALLPASSENGER_DATAS }),

  getPassengersData: (id) => ({
    type: actions.GET_PASSENGER_DATAS,
    payload: { id: id },
  }),

  add: (sendData) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.ADD_PASSENGER,
        payload: { sendData: sendData },
      });
    };
  },
  // getById: (id) => {
  //   return (dispatch) => {
  //     dispatch({ type: actions.GET_PASSENGER_BY_ID, payload: { id: id } });
  //   };
  // },
  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_PASSENGER,
        payload: { id: selected },
      });
    };
  },
  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PASSENGER,
        payload: { sendData: sendData, id: id },
      });
    };
  },
};
export default actions;
