import actions from './actions';
const initState = {  
  receipes:[],
  isReceipeDelete:false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_RECEIPES_REDUCER:
      return {
        ...state,             
        receipes: action.data,
        isReceipeDelete:false
      };
    case actions.DELETE_RECEIPE_SUCCESS:
      return {
        ...state,        
        isReceipeDelete: true
      };
    default:
      return state;
  }
}
