const actions = {
  GET_RECEIPES: "GET_RECEIPES",
  ADD_RECEIPE: "ADD_RECEIPE",
  GET_RECEIPES_REDUCER:"GET_RECEIPES_REDUCER",
  UPDATE_RECEIPE: "UPDATE_RECEIPE",
  DELETE_RECEIPE:"DELETE_RECEIPE",
  DELETE_RECEIPE_SUCCESS:"DELETE_RECEIPE_SUCCESS",

  addReceipe: (sendData) => {
    return (dispatch, getState) => {
      dispatch({ type: actions.ADD_RECEIPE, payload: { sendData: sendData } });
    };
  },

  getReceipeData: (assetId) => {
    return (dispatch) => {      
      dispatch({ type: actions.GET_RECEIPES, payload: { id: assetId } });
    };
  },

  deleteReceipeData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_RECEIPE,
        payload: {id:selected}
      });
    };
  },
  
  updateReceipeData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_RECEIPE,
        payload: { sendData: sendData, id: id },
      });
    };
  },
};
export default actions;
